import { rest } from "@/store/api";
import { RootState } from "@/store/types";
import { ActionTree } from "vuex/types";
import { GenAIState } from "./types";

const actions: ActionTree<GenAIState, RootState> = {
  GENAI_GET_MODULES: async ({ state, commit, dispatch }, moduleId) => {
    const modules = await rest("get", "genai_get_modules");
    commit("GENAI_SET_MODULES", modules);
    return modules;
  },
  GENAI_SELECT_MODULE: async ({ state, commit, dispatch }, moduleId) => {
    commit("GENAI_SET_SELECTED_MODULE_ID", moduleId);
  },
  GENAI_SELECT_FUNCTION: async ({ state, commit, dispatch }, functionId) => {
    commit("GENAI_SET_SELECTED_FUNCTION_ID", functionId);
  },
  GENAI_SUBMIT: async ({ state, commit, dispatch }, payload) => {
    // TODO:
  },
  GENAI_SEMANTIC_SEARCH: async ({ state, commit, dispatch }, payload) => {
    const query_result = await rest("post", "genai_semantic_search", payload);
    return query_result;
  },
  GENAI_SEMANTIC_ASK: async ({ state, commit, dispatch }, payload) => {
    const query_result = await rest("post", "genai_semantic_ask", payload);
    return query_result;
  },
  GENAI_UPLOAD_KNOWLEDGE: async ({ state, commit, dispatch }, payload) => {
    //Write code to send file to backend server
    const { files, tags, uploadedBy } = payload;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i].raw);
    }
    formData.append("tags", JSON.stringify(tags));
    formData.append("uploadedBy", uploadedBy);
    const result = await rest("post", "genai_semantic_upload", formData);
    return result;
  },
  GENAI_GET_UPLOADED_DOCUMENTS: async ({ state, commit, dispatch }, payload) => {
    commit("SET_LOADING_DOCUMENTS", true);
    const documents = await rest("post", "genai_get_uploaded_documents", payload);

    commit("GENAI_SET_UPLOADED_DOCUMENTS", documents);

    commit("SET_LOADING_DOCUMENTS", false);
    return documents;
  },
  GENAI_GET_DOCUMENT_STATUS: async ({ state, commit, dispatch }, payload) => {
    try {
      const documentStatus = await rest("post", "genai_get_document_status", {
        documentId: payload.documentId,
      });
      return documentStatus;
    } catch (error) {
      return error;
    }
  },
  GENAI_DELETE_DOCUMENT: async ({ state, commit, dispatch }, payload) => {
    try {
      commit("SET_LOADING_DOCUMENTS", true);

      const documentId = payload.documentId;
      const updatedDocuments = await rest("post", "genai_delete_document", { documentId });
      commit("SET_LOADING_DOCUMENTS", false);
      return updatedDocuments;
    } catch (error) {
      commit("SET_LOADING_DOCUMENTS", false);
      return error;
    }
  },
  GENAI_GET_ARTICLES: async ({ state, commit, dispatch }, payload) => {
    const result = await rest("post", "genai_get_articles", payload);
    commit("GENAI_SET_ARTICLES", result.rows);
    return result;
  },
  GENAI_CREATE_ARTICLE: async ({ state, commit, dispatch }, payload) => {
    try {
      await rest("post", "genai_create_article", payload);
      await dispatch("GENAI_GET_ARTICLES");
      return { success: true };
    } catch (error) {
      commit("SET_LOADING_DOCUMENTS", false);
      return { success: false, error };
    }
  },
  GENAI_UPLOAD_ARTICLE: async ({ state, commit, dispatch }, payload) => {
    try {
      await rest("post", "genai_upload_article", payload);
      const page = payload.page || 1;
      await dispatch("GENAI_GET_ARTICLES", { page });
      return { success: true };
    } catch (error) {
      commit("SET_LOADING_DOCUMENTS", false);
      return { success: false, error };
    }
  },
  GENAI_DELETE_ARTICLE: async ({ state, commit, dispatch }, payload) => {
    try {
      await rest("post", "genai_delete_article", { id: payload.id });
      await dispatch("GENAI_GET_ARTICLES");
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
  GENAI_UPDATE_ARTICLE: async ({ state, commit, dispatch }, payload) => {
    try {
      const newArticle = payload.newArticle;
      const page = payload.page || 1;
      await rest("post", "genai_update_article", newArticle);
      await dispatch("GENAI_GET_ARTICLES", { page });
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
  GENAI_GET_WEBSITES: async ({ state, commit, dispatch }, payload) => {
    const result = await rest("post", "genai_get_websites", payload);
    commit("GENAI_SET_WEBSITES", result.rows);
    return result;
  },
  GENAI_CREATE_WEBSITE: async ({ state, commit, dispatch }, payload) => {
    try {
      await rest("post", "genai_create_website", payload);
      await dispatch("GENAI_GET_WEBSITES");
      return { success: true };
    } catch (error) {
      commit("SET_LOADING_DOCUMENTS", false);
      return { success: false, error };
    }
  },
  GENAI_UPLOAD_WEBSITE: async ({ state, commit, dispatch }, payload) => {
    try {
      await rest("post", "genai_upload_website", payload);
      const page = payload.page || 1;

      await dispatch("GENAI_GET_WEBSITES", { page });
      return { success: true };
    } catch (error) {
      commit("SET_LOADING_DOCUMENTS", false);
      return { success: false, error };
    }
  },
  GENAI_DELETE_WEBSITE: async ({ state, commit, dispatch }, payload) => {
    try {
      await rest("post", "genai_delete_website", { id: payload.id });
      await dispatch("GENAI_GET_WEBSITES");
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
  GENAI_UPDATE_WEBSITE: async ({ state, commit, dispatch }, payload) => {
    try {
      await rest("post", "genai_update_website", payload.newWebsite);

      const page = payload.page || 1;
      await dispatch("GENAI_GET_WEBSITES", { page });
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
  GENAI_UPLOAD_PLUGIN: async ({ state, commit, dispatch }, payload) => {
    try {
      const { files, brain } = payload;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i].raw);
      }
      formData.append("brain", brain);
      const resp = await rest("post", "genai_upload_plugins", formData);
      return { success: true, data: resp };
    } catch (error) {
      return { success: false, error };
    }
  },
  GENAI_GET_OTHERS_DOCUMENTS: async ({ state, commit, dispatch }) => {
    const result = await rest("get", "genai_get_others_documents");
    return result;
  },
  GENAI_GET_RESPONSE: async ({ state, commit, dispatch }, payload) => {
    const result = await rest("post", "genai_get_preview_response", payload);
    return result;
  },
  GENAI_SEARCH_DOCUMENTS: async ({ state, commit, dispatch }, payload) => {
    const result = await rest("post", "genai_search_documents", payload);
    switch (payload.documentType) {
      case "articles":
        commit("GENAI_SET_ARTICLES", result);
        break;
      case "websites":
        commit("GENAI_SET_WEBSITES", result);
        break;
      case "documents": {
        commit("GENAI_SET_UPLOADED_DOCUMENTS", { rows: result, count: result.length });
        break;
      }
      default:
        break;
    }
    return result;
  },
  GENAI_GET_PERSONAS: async ({ state, commit, dispatch }) => {
    const result = await rest("get", "genai_get_personas");
    commit("GENAI_SET_PERSONAS", result);
    return result;
  },
  GENAI_CREATE_PERSONA: async ({ state, commit, dispatch }, payload) => {
    try {
      await rest("post", "genai_create_persona", { persona: payload.persona });
      await dispatch("GENAI_GET_PERSONAS");
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
  GENAI_UPDATE_PERSONA: async ({ state, commit, dispatch }, payload) => {
    try {
      await rest("post", "genai_update_persona", { persona: payload.persona });
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
  GENAI_DELETE_PERSONA: async ({ state, commit, dispatch }, payload) => {
    try {
      await rest("post", "genai_delete_persona", { id: payload.id });
      await dispatch("GENAI_GET_PERSONAS");
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
  GENAI_UPDATE_DOCUMENT: async ({ state, commit, dispatch }, payload) => {
    try {
      await rest("post", "genai_update_document", payload);
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  },
};

export default actions;
