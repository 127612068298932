<template>
  <div class="login-container">
    <div v-if="!forgotPasswordVisible && !mfaVisible" class="login-box">
      <h1 class="login-title">Login</h1>
      <el-form @submit.native.prevent="handleSubmit" :model="form" :rules="rules" ref="loginForm">
        <el-form-item label="Email" prop="email">
          <el-input v-model="form.email" @input="validateEmail" name="email"></el-input>
        </el-form-item>

        <el-form-item label="Password" prop="password">
          <el-input type="password" v-model="form.password" @input="validatePassword"></el-input>
          <span v-if="passwordError" class="error">{{ passwordError }}</span>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSubmit" native-type="submit" :loading="isLoading"
            >Login</el-button
          >
        </el-form-item>

        <!-- forgot password -->
        <el-form-item>
          <el-button type="text" @click="handleForgotPassword">Forgot Password ? </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- forgot password dialog -->
    <div v-if="forgotPasswordVisible" class="forgot-password-dialog">
      <h1 class="login-title">Reset Password</h1>
      <el-form :model="forgotPasswordForm" :rules="rules" ref="forgotPasswordForm">
        <el-form-item label="Email" prop="email">
          <el-input
            v-model="forgotPasswordForm.email"
            @input="validateEmail"
            name="email"
            placeholder="Enter your email to receive reset password link"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="forgotPasswordVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleForgotPasswordSubmit" :loading="isLoading"
          >Submit</el-button
        >
      </span>
    </div>

    <!-- MFA Views -->
    <div v-if="!isFetchingMfa && mfaVisible && mfaTypes.length > 0" class="mfa-dialog">
      <h1 class="login-title">Multi-Factor Authentication</h1>
      <!-- render MFA types , each type is a button -->
      <div class="mfa-types" v-if="!selectedMfaType">
        <div v-for="mfaType in mfaTypes" :key="mfaType.id" style="margin-bottom: 10px">
          <div v-if="mfaType.active">
            <el-button type="plain" @click="handleMfaSelect(mfaType)" style="width: 100%">
              <span style="text-transform: capitalize" v-if="mfaType.authenticator_type !== 'oob'">
                {{ mfaNameMapping[mfaType.authenticator_type] }}
              </span>
              <span v-else>{{
                `${mfaNameMapping[mfaType.authenticator_type]} ${mfaType.oob_channel}`
              }}</span>
            </el-button>
          </div>
        </div>
        <el-button type="text" @click="mfaVisible = false">Cancel</el-button>
      </div>
      <div v-else class="mfa-code">
        <el-form
          :model="mfaForm"
          :rules="mfaRules"
          ref="mfaForm"
          @submit.native.prevent="handleMfaSubmit"
        >
          <el-form-item label="Code" prop="code">
            <el-input v-model="mfaForm.code" @input="validateMfaCode" name="code"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleMfaSubmit"
              native-type="submit"
              :loading="mfaLoading"
              >Submit</el-button
            >
            <el-button type="text" @click="mfaVisible = false">Cancel</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div v-else-if="!isFetchingMfa && mfaTypes.length === 0 && mfaVisible" class="mfa-dialog">
      <h1 class="login-title">Secure Your Account</h1>
      <p style="color: #525457; font-size: 14px; text-align: center">
        Scan the QR Code below using your preferred authenticator app and then enter the provided
        one-time code below.
      </p>
      <div class="mfa-qr">
        <canvas ref="mfaQrCodeCanvas"></canvas>
      </div>

      <el-form
        :model="mfaForm"
        :rules="mfaRules"
        ref="mfaForm"
        @submit.native.prevent="handleConfirmEnroll"
      >
        <el-form-item label="Code" prop="code">
          <el-input v-model="mfaForm.code" @input="validateMfaCode" name="code"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleConfirmEnroll"
            :loading="mfaLoading"
            style="width: 100%"
            native-type="submit"
            >Submit</el-button
          >
          <el-button
            type="danger"
            plain
            @click="mfaVisible = false"
            style="width: 100%; margin: 10px 0"
            >Cancel</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { config } from "@/helpers/apiConfig";
import axios from "axios";
import { enrichSentryUserData } from "@/sentry";
import LocalStorageManager from "@/localStorageManager";
import QRCode from "qrcode";

export default {
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      emailError: "",
      passwordError: "",
      mfaCodeError: "",
      rules: {
        email: [
          { required: true, message: "Please input email", trigger: "blur" },
          { type: "email", message: "Invalid email address", trigger: ["blur", "change"] },
        ],
        password: [
          { required: true, message: "Please input password", trigger: "blur" },
          { min: 6, message: "Password must be at least 6 characters", trigger: "blur" },
        ],
      },
      isLoading: false,
      forgotPasswordVisible: false,
      forgotPasswordForm: {
        email: "",
      },
      forgotPasswordError: "",
      mfaVisible: false,
      mfaForm: {
        code: "",
      },
      mfaRules: {
        code: [{ required: true, message: "Please input code", trigger: "blur" }],
      },
      mfaLoading: false,
      mfaExchangeToken: "",
      mfaTypes: [],
      mfaNameMapping: {
        otp: "One-Time Password (OTP)",
        "recovery-code": "Use a Recovery Code",
        oob: "Send Code to",
      },
      selectedMfaType: null,
      mfaQrCode: "",
      isFetchingMfa: false,
    };
  },
  methods: {
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailError = emailPattern.test(this.form.email) ? "" : "Invalid email address";
    },
    validatePassword() {
      this.passwordError =
        this.form.password.length >= 6 ? "" : "Password must be at least 6 characters";
    },
    validateMfaCode() {
      this.mfaCodeError = this.mfaForm.code.length > 0 ? "" : "Please input code";
    },
    async handleSubmit() {
      this.isLoading = true;
      this.$refs.loginForm
        .validate()
        .then(async (valid) => {
          if (valid) {
            try {
              const { data } = await axios.post(`${config.root}/api/auth0-login-custom`, {
                email: this.form.email,
                password: this.form.password,
              });

              await this.handleSetUser(data);
            } catch (error) {
              await this.handleLoginError(error);
            } finally {
              this.isLoading = false;
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    async handleLoginError(error) {
      const message = error.response?.data?.error_description || "Failed to login";
      const errorType = error.response?.data?.error || "";

      if (errorType === "mfa_required") {
        this.mfaVisible = true;
        this.mfaLoading = false;
        this.mfaExchangeToken = error.response?.data?.mfa_token || "";

        const mfaTypes = await this.fetchMfaTypes();
        if (!mfaTypes) return;

        if (mfaTypes.length === 0) {
          await this.enrollMfa();
        }
        return;
      }

      this.$notify.error({ title: "Error", message });
    },

    async fetchMfaTypes() {
      try {
        this.isFetchingMfa = true;
        const resp = await axios.post(`${config.root}/api/auth0/mfa-exchange`, {
          mfa_token: this.mfaExchangeToken,
        });

        if (resp.status === 200) {
          this.mfaTypes = resp.data.filter(
            (mfaType) =>
              mfaType.active &&
              mfaType.authenticator_type !== "oob" &&
              mfaType.authenticator_type !== "recovery-code"
          );
          return this.mfaTypes;
        }
      } catch (error) {
        this.mfaVisible = false;
        this.$notify.error({ title: "Error", message: "Failed to get MFA types" });
      } finally {
        this.isFetchingMfa = false;
      }
      return null;
    },

    async enrollMfa() {
      try {
        const { data } = await axios.post(`${config.root}/api/auth0/mfa-enroll`, {
          mfa_token: this.mfaExchangeToken,
        });

        QRCode.toCanvas(this.$refs.mfaQrCodeCanvas, data.barcode_uri, (error) => {
          if (error) console.error(error);
        });
      } catch (error) {
        this.$notify.error({ title: "Error", message: "Failed to enroll MFA" });
      }
    },
    handleForgotPassword() {
      this.forgotPasswordVisible = true;
    },
    async handleForgotPasswordSubmit() {
      this.isLoading = true;
      this.$refs.forgotPasswordForm.validate(async (valid) => {
        if (valid) {
          try {
            await axios.post(config.root + "/api/auth0/reset-password", {
              email: this.forgotPasswordForm.email,
            });
            this.forgotPasswordVisible = false;
            this.isLoading = false;
            this.$notify.success({
              title: "Success",
              message: "Reset password email sent successfully",
            });
          } catch (error) {
            const message =
              error.response?.data?.error_description || "Failed to send reset password email";
            this.isLoading = false;
            this.$notify.error({
              title: "Error",
              message,
            });
          }
        } else {
          this.isLoading = false;
          return false;
        }
      });
    },
    async handleMfaSelect(mfaType) {
      this.selectedMfaType = mfaType;
      this.mfaVisible = true;
    },
    async handleMfaSubmit() {
      this.$refs.mfaForm
        .validate()
        .then(async (valid) => {
          if (valid) {
            try {
              this.mfaLoading = true;
              if (this.selectedMfaType.authenticator_type === "otp") {
                const resp = await axios.post(config.root + "/api/auth0/challenge-mfa", {
                  mfa_token: this.mfaExchangeToken,
                  otp: this.mfaForm.code,
                  mfa_type: "otp",
                });
                this.mfaVisible = false;
                this.mfaLoading = false;
                await this.handleSetUser(resp);
              } else if (this.selectedMfaType.authenticator_type === "recovery-code") {
                const resp = await axios.post(config.root + "/api/auth0/challenge-mfa", {
                  mfa_token: this.mfaExchangeToken,
                  recovery_code: this.mfaForm.code,
                  mfa_type: "recovery-code",
                });
                this.mfaVisible = false;
                this.mfaLoading = false;
                await this.handleSetUser(resp);
              }
            } catch (error) {
              this.mfaLoading = false;
              const message = error.response?.data?.error_description || "Failed to enroll MFA";
              this.$notify.error({
                title: "Error",
                message,
              });
            }
          }
        })
        .catch(() => {
          this.mfaLoading = false;
        });
    },
    async handleSetUser(data) {
      const { user, token } = data.data;
      this.$auth.accessToken = token.access_token;

      const tokenExpiry = new Date(Date.now() + token.expires_in * 1000);

      LocalStorageManager.setAuth0CustomLoginToken({
        token: token.access_token,
        expiry: tokenExpiry,
      });

      this.$auth.user = user;
      enrichSentryUserData(user);
    },
    async handleMfaEnroll() {
      this.mfaVisible = true;
    },
    async handleConfirmEnroll() {
      this.mfaLoading = true;
      this.$refs.mfaForm
        .validate()
        .then(async (valid) => {
          if (valid) {
            try {
              const resp = await axios.post(config.root + "/api/auth0/challenge-mfa", {
                mfa_token: this.mfaExchangeToken,
                otp: this.mfaForm.code,
                mfa_type: "otp",
              });
              this.mfaVisible = false;
              this.mfaLoading = false;
              await this.handleSetUser(resp);
            } catch (error) {
              this.mfaLoading = false;
              const message = error.response?.data?.error_description || "Failed to enroll MFA";
              this.$notify.error({
                title: "Error",
                message,
              });
            }
          }
        })
        .catch(() => {
          this.mfaLoading = false;
        });
    },
    handleMfaCancel() {
      this.selectedMfaType = null;
    },
  },
};
</script>

<style>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.login-box {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.error {
  color: red;
  font-size: 0.8rem;
}

.forgot-password-dialog {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.mfa-dialog {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.mfa-qr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
</style>
